
import React from 'react';
import me from '../content/me.png';
import './AboutSection.css';

// animation package
import { Fade } from "react-awesome-reveal";

export const AboutSection = () => {

    const writeup = {
        first: "My primary interest lies within the financial technology sphere, with areas such as algorithmic trading and quantitative finance. Currently, I am looking for opportunities to learn and expand my knowledge in these fields.",
        second: "Some technologies I have been working with:",
        stack: [
            "Python",
            "C++",
            "Java",
            "R",
            "Julia",
            "React.js",],
        third: "Outside of work, I'm interested in creating personal projects like ones below and meeting new people with similar interests.",
    }

    return (
        <section className='aboutBackground'>
            <div className='aboutSectionText'>
                <div className='aboutSectionTitle'>
                    <h1 className='sectionNumber'> 01.</h1>
                    <h2 className='sectionTitle'> About me</h2>
                </div>

                <div className='aboutSectionMainText'>
                    <Fade direction='up' triggerOnce={false}>
                        <div>
                            <p>{writeup["first"]}</p>
                            <p>{writeup["second"]}</p>
                        </div>
                    </Fade>
                    <div className='aboutSectionSkills'>
                        <ul className="tech-stack">
                            {writeup["stack"].map(function (tech, i) {
                                return (
                                    <Fade direction='up' cascade delay={500+i*250} triggerOnce={false}>
                                        <li key={i}>{tech}</li>
                                    </Fade>
                                );
                            })}
                        </ul>
                    </div>
                    <Fade direction='up' triggerOnce={false}>
                        <p>{writeup["third"]}</p>
                    </Fade>
                </div>

            </div>
            <div className='aboutSectionImage'>
                <img src={me} alt='myself' />
            </div>
        </section>
    )
}